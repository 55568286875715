import pino from 'pino';
import { logflarePinoVercel } from 'pino-logflare';
import { loggerConfig } from 'src/config';

const { stream, send } = logflarePinoVercel({
  apiKey: loggerConfig.apiKey,
  sourceToken: loggerConfig.sourceToken,
});

// create pino logger
const logger = pino(
  {
    browser: {
      transmit: {
        level: 'info',
        send: send,
      },
    },
    level: 'debug',
    base: {
      env: process.env.NODE_ENV,
      // revision: process.env.VERCEL_GITHUB_COMMIT_SHA,
    },
  },
  stream
);

export default logger;
